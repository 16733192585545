
import { Component, Vue } from '@/lib/decorator';
import TitleTab from '../_titleTab.vue';

import PaginationBlock from '@/components/Pagination.vue';
import FilterInstant from '@/components/FilterInstant.vue';

import EmployeesModule from '@/store/employees';
import EmployeeEntityModule from '@/store/employees/entity';
import EmployeeLogsModule from '@/store/employees/logs';
import AppTable from '@/components/ui/table/Table.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    TitleTab,
    AppCol,
    AppRow,
    TextDatetime,
    AppTable,
    PaginationBlock,
    FilterInstant,
  },
  beforeMount() {
    this.employeeId = this.$route.params.employeeId as string;
  },
  mounted() {
    EmployeeEntityModule.initEdit(this.employeeId);
    EmployeeLogsModule.init(this.employeeId);
  },
})
export default class EmployeesEntityIndex extends Vue {
  employeeId = '';

  get settings(): PageInterface {
    return EmployeeLogsModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return EmployeeLogsModule.filter.filterSettings;
  }

  get currentTab(): string {
    return EmployeeEntityModule.tabsNav.logs?.id;
  }

  sort(header: { id: string; sort: { value: string } }): void {
    EmployeeLogsModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  selectAmount(value: string): void {
    EmployeeLogsModule.updatePageAmountItems(value);
  }

  async updateList(): Promise<void> {
    await EmployeeLogsModule.setPageToBegin();
    if (EmployeesModule.pageSettings.titleEdit === '') {
      await EmployeeEntityModule.initEdit(this.employeeId);
    }

    await EmployeeLogsModule.filter.updateFilter();
    await EmployeeLogsModule.updateList();
  }
}
