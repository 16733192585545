import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mr-admin-pages__wrapper"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "cp-pagination-amount" }
const _hoisted_6 = {
  key: 0,
  class: "cp-select-pagination-list__wrapper"
}
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_icon_font = _resolveComponent("icon-font")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mr-admin-pagination__wrapper", { empty: Object.keys(_ctx.dataValue).length === 0 }])
  }, [
    (Object.keys(_ctx.dataValue).length > 0)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "mr-pagination-left-icon mr-pagination-btn",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.before && _ctx.before(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: "cp-btn-add",
            icon: ['fas', 'chevron-left']
          })
        ]))
      : _createCommentVNode("", true),
    (Object.keys(_ctx.dataValue).length > 0)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataValue.totalPages, (number) => {
            return _renderSlot(_ctx.$slots, "default", {}, () => [
              (number <= 2 || number > _ctx.dataValue.totalPages - 2)
                ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
                    (_openBlock(), _createElementBlock("span", {
                      key: number,
                      class: _normalizeClass(["mr-admin-page", { active: number === _ctx.dataValue.currentPage }]),
                      onClick: ($event: any) => (_ctx.setPage(number))
                    }, _toDisplayString(number), 11, _hoisted_2))
                  ])
                : (number >= _ctx.dataValue.currentPage - 1 && number <= _ctx.dataValue.currentPage + 1)
                  ? _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
                      (_openBlock(), _createElementBlock("span", {
                        key: number,
                        class: _normalizeClass(["mr-admin-page", { active: number === _ctx.dataValue.currentPage }]),
                        onClick: ($event: any) => (_ctx.setPage(number))
                      }, _toDisplayString(number), 11, _hoisted_3))
                    ])
                  : (
            number === Math.floor((_ctx.startPage + _ctx.dataValue.currentPage) / 2) ||
            number === Math.floor((_ctx.dataValue.currentPage + _ctx.dataValue.totalPages + 1) / 2)
          )
                    ? _renderSlot(_ctx.$slots, "default", { key: 2 }, () => [
                        (_openBlock(), _createElementBlock("span", {
                          key: number,
                          class: _normalizeClass(["mr-admin-page", { active: number === _ctx.dataValue.currentPage }]),
                          onClick: ($event: any) => (_ctx.setPage(number))
                        }, " ... ", 10, _hoisted_4))
                      ])
                    : _createCommentVNode("", true)
            ])
          }), 256))
        ]))
      : _createCommentVNode("", true),
    (Object.keys(_ctx.dataValue).length > 0)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: "mr-pagination-right-icon mr-pagination-btn",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: "cp-btn-add",
            icon: ['fas', 'chevron-right']
          })
        ]))
      : _createCommentVNode("", true),
    (Object.keys(_ctx.dataValue).length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          ref: "dropdownAmountSelect",
          class: _normalizeClass(["cp-pagination-amount__wrapper", _ctx.stateClass]),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args)))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.amountItems) + " ", 1),
            _createVNode(_component_icon_font, {
              icon: "down",
              size: "5"
            })
          ]),
          (_ctx.visibleList)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listAmountItems, (amount) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `mr-select-default-list_${amount}`,
                    class: "cp-select-pagination-list cp-input-radius-50",
                    onClick: ($event: any) => (_ctx.selectAmount(amount))
                  }, _toDisplayString(amount), 9, _hoisted_7))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}