
import { Component, Vue, Prop } from '@/lib/decorator';

import IconFont from '@/components/icons/IconFont.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronRight, faChevronLeft);

interface PaginationInterface {
  currentPage: number;
  totalPages: number;
}

@Component({
  components: {
    IconFont,
  },
  emits: ['click', 'selectAmount'],
})
export default class Pagination extends Vue {
  @Prop({
    required: true,
    default: () => {
      return {};
    },
  })
  readonly dataValue!: PaginationInterface;
  @Prop({ required: true }) readonly action!: string;
  @Prop({ required: false }) readonly total_items?: string;
  @Prop({ required: false, default: '100' }) readonly amountItems?: string;

  startPage = 1;
  stateClass = '';
  classOpen = 'open';
  listAmountItems = ['50', '100', '500', '1000'];
  visibleList = false;

  setPage(number: number) {
    this.$store.dispatch(this.action, number);
  }

  next() {
    const page =
      this.dataValue.currentPage + 1 <= this.dataValue.totalPages
        ? this.dataValue.currentPage + 1
        : this.dataValue.currentPage;
    this.$store.dispatch(this.action, page);
  }

  before() {
    const page = this.dataValue.currentPage - 1 > 0 ? this.dataValue.currentPage - 1 : this.dataValue.currentPage;
    this.$store.dispatch(this.action, page);
  }

  toggleSelect() {
    this.stateClass = this.stateClass === this.classOpen ? '' : this.classOpen;
    this.visibleList = !this.visibleList;
  }

  selectAmount(value: string) {
    this.$emit('selectAmount', value);
  }

  documentClick(e: Event) {
    const el: HTMLElement = this.$refs.dropdownAmountSelect as HTMLElement;
    const target: EventTarget | null = e.target;

    if (this.visibleList) {
      if (el && el !== target && !el.contains(target as HTMLElement)) {
        this.visibleList = false;
        this.stateClass = '';
      }
    }
  }

  created() {
    document.body.addEventListener('click', this.documentClick);
  }

  updated() {
    document.body.addEventListener('click', this.documentClick);
  }

  destroyed() {
    document.body.addEventListener('click', this.documentClick);
  }
}
